import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import bgImage from '../images/regulations-bg.svg'
import './regulamin.css'


import "../components/fontawsomeIcon"



const StyledHidden = styled.div`
  overflow: hidden;
`

const StyledBackgroundWrapper = styled.div`
   background-image:url(${bgImage}) ;
  background-position: center;
  background-size: cover;
  width:100%;
  height:100%;
  margin-bottom:100px;


`;

const StyledContainer= styled.div`
  max-width: 1440px;
  padding:0 32px;
  margin:220px auto 0;
  position:relative;
  @media (max-width:1439px){
    margin:140px auto 0;
  }


`

const StyledMainHeader = styled.h2`
  font-size:64px;
  line-height: 71px;
  font-weight: 800;
  color:#07064E;
  max-width: 50%;
   @media (max-width:1439px){
    font-size:40px;
    line-height:60px;
    max-width: 100%;
  }
`

const StyledModuleWrapper= styled.div`
  width:80%;
  margin:0 auto;
  margin-top:120px;
  @media (max-width:1439px){
    margin-top:70px;
  }
   @media (max-width:1023px){
     width:100%;
  }

`

const StyledDigitHeader = styled.h3`
  margin:70px 0 40px;
  font-size:30px;
  line-height: 85px;
  font-weight: 800;
  color:#07064E;
  @media (max-width:767px){
       margin:50px 0 30px;
        font-size:24px;
        line-height: 30px;
        
  }
`;

const StyledParagraph = styled.h3`
  font-size:16px;
  line-height: 31px;
  font-weight: 400;
  color:#07064E;
  &.bold-uppercase{
    font-weight:600 ;
    text-transform: uppercase;
  }
`;







const regulamin = () => {



  return (
    <Layout>
      <StyledHidden>
        <StyledBackgroundWrapper>
        <StyledContainer>
          <StyledMainHeader>Regulamin aplikacji
            Wyborek</StyledMainHeader>
          <StyledModuleWrapper>


            {/*<StyledDigitHeader>01. REGULAMIN – WYBOREK.PL</StyledDigitHeader>*/}
            <StyledParagraph>
              1. Niniejszy Regulamin określa zasady korzystania z Platformy.<br /><br />
              2. Platforma prowadzona jest przez PLEO interactive spółka z ograniczoną odpowiedzialnością z siedzibą w Krakowie, ul. Bolesława Limanowskiego 36/30, 30-551 Kraków, wpisaną do Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy dla Krakowa – Śródmieścia w Krakowie, Wydział XI Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000429590, NIP: 6793084198, REGON: 122635706, kapitał zakładowy: 5.000,00 PLN.<br /><br />
              3. Wskazane poniżej terminy użyte w Regulaminie mają następujące znaczenie:<br /><br />

            </StyledParagraph>


            <table className="regulamin">
              <thead>
              <tr>
                <th className="centerBold">Cennik</th>
                <th>wykaz Planów Abonamentowych dostępny na Platformie</th>
              </tr>
              </thead>
              <tfoot>

              </tfoot>
              <tbody>
              <tr>
                <td className="centerBold">Formularz Rejestracji</td>
                <td>funkcjonalność Platformy pozwalająca na wypełnienie przez Użytkownika pól na stronie internetowej (podanie odpowiednich danych) w celu założenia Konta</td>
              </tr>
              <tr>
                <td className="centerBold">Głosowanie</td>
                <td>funkcjonalność Platformy pozwalająca na tworzenie zestawu Pytań w ramach Wydarzenia </td>
              </tr>
              <tr>
                <td className="centerBold">Klient</td>
                <td>podmiot wskazany w profilu Organizacji jako podmiot, na rzecz którego świadczona jest usługa Tworzenia Wydarzenia, zobowiązany do uiszczania Opłat</td>
              </tr>
              <tr>
                <td className="centerBold">Klient Testowy</td>
                <td>Użytkownik,   na   rzecz   którego   świadczona  jest   usługaTworzenia Wydarzenia Testowego</td>
              </tr>
              <tr>
                <td className="centerBold">Konsument</td>
                <td>Osoba będąca osobą fizyczną, zawierająca umowę dotyczącą usługi Konta, usługi Tworzenia Wydarzenia, usługi   Tworzenia   Wydarzenia   Testowego  lub usługi Oddania Głosu, niezwiązaną bezpośrednio z jego działalnością gospodarczą lub zawodową </td>
              </tr>
              <tr>
                <td className="centerBold">Konto</td>
                <td>funkcjonalność Platformy, umożliwiająca Użytkownikowi, po zalogowaniu się na Platformie za pomocą indywidualnego loginu i hasła, dostęp do zasobów w systemie informatycznym</td>
              </tr>
              <tr>
                <td className="centerBold">Miesiąc Rozliczeniowy</td>
                <td>każde kolejne 31 dni w ramach Subskrypcji   rocznej liczone od dnia aktywacji Subskrypcji zgodnie z Planem Abonamentowym przez Klienta</td>
              </tr>
              <tr>
                <td className="centerBold">Oddanie Głosu</td>
                <td>udzielenie odpowiedzi na Pytanie</td>
              </tr>
              <tr>
                <td className="centerBold">Opłata</td>
                <td>kwota należna zgodnie z wybranym przez Klienta Planem Abonamentowym </td>
              </tr>
              <tr>
                <td className="centerBold">Organizacja</td>
                <td>grupa Użytkowników, którzy mogą korzystać z usługi Tworzenia Wydarzenia świadczonej na rzecz Klienta</td>
              </tr>
              <tr>
                <td className="centerBold">Osoba</td>
                <td>Użytkownik, Uczestnik, Klient lub Klient Testowy</td>
              </tr>
              <tr>
                <td className="centerBold">Pełnomocnik</td>
                <td>Uczestnik oddający głos w cudzym imieniu</td>
              </tr>
              <tr>
                <td className="centerBold">Plan Abonamentowy</td>
                <td>opisany w Cenniku wariant korzystania z usługi Tworzenia Wydarzenia określający m.in. (i) dostępne w ramach tego Planu Abonamentowego funkcjonalności oraz sposób korzystania z nich, w tym ograniczenia dotyczące korzystania z usługi Tworzenia Wydarzenia (ii) wysokość Opłaty w zależności od rodzaju Subskrypcji.</td>
              </tr>
              <tr>
                <td className="centerBold">Platforma</td>
                <td>platforma internetowa Wyborek dostępna pod adresem: https://wyborek.pl</td>
              </tr>
              <tr>
                <td className="centerBold">PLEO</td>
                <td>podmiot, o którym mowa w § 1 ust. 2</td>
              </tr>
              <tr>
                <td className="centerBold">Pytanie</td>
                <td>pytanie zadawane Uczestnikom przez Użytkownika w ramach Głosowania</td>
              </tr>
              <tr>
                <td className="centerBold">Regulamin</td>
                <td>niniejszy regulamin</td>
              </tr>
              <tr>
                <td className="centerBold">Subskrypcja</td>
                <td>umowana  Tworzenie Wydarzenia,  zawierana  na czasokreślony wynoszący  31 dni (Subskrypcja miesięczna)lub 12 miesięcy (Subskrypcja roczna)</td>
              </tr>
              <tr>
                <td className="centerBold">Tworzenie Wydarzenia</td>
                <td>utworzenie Wydarzenia na Platformie w ramach wybranego Planu Abonamentowego</td>
              </tr>
              <tr>
                <td className="centerBold">Tworzenie Wydarzenia Testowego </td>
                <td>utworzenie Wydarzenia Testowego na Platformie  przezKlienta Testowego</td>
              </tr>
              <tr>
                <td className="centerBold">Uczestnik</td>
                <td>osoba mająca możliwość Oddania Głosu</td>
              </tr>
              <tr>
                <td className="centerBold">Usługi</td>
                <td>usługi oferowane przez PLEO na rzecz Osób w ramach Platformy</td>
              </tr>
              <tr>
                <td className="centerBold">Użytkownik</td>
                <td>osoba, która założyła Konto</td>
              </tr>
              <tr>
                <td className="centerBold">Wydarzenie</td>
                <td>funkcjonalność Platformy pozwalająca Użytkownikowi na organizację jednego Głosowania lub większej liczby Głosowań oraz porozumiewanie się za pośrednictwem czatu lub wideokonferencji</td>
              </tr>
              <tr>
                <td className="centerBold">Wydarzenie Testowe</td>
                <td>bezpłatny wariant Wydarzenia  dostępny na warunkachopisanych w Regulaminie</td>
              </tr>

              </tbody>
            </table>


            <StyledDigitHeader>02. Postanowienia ogólne</StyledDigitHeader>
            <StyledParagraph>
              1. Regulamin określa zasady korzystania z Platformy oraz z Usług, jak również określa zakres praw i obowiązków PLEO oraz Osób. Warunkiem korzystania z Usług jest akceptacja Regulaminu.<br /><br />
              2. PLEO świadczy na rzecz Osób następujące Usługi drogą elektroniczną:<br /><br />
                <span className='innerText'> 1) usługę Tworzenia Wydarzenia,</span><br />
              <span className='innerText'> 2) usługę Tworzenia Wydarzenia Testowego,</span><br />
                <span className='innerText'> 3) usługę Konta,</span><br />
                <span className='innerText'> 4) usługę Oddania Głosu.</span><br /><br />
              3. Usługa Tworzenia Wydarzenia świadczona jest wyłącznie na rzecz Klienta.<br /><br />
              4.Usługa Tworzenia Wydarzenia Testowego świadczona jest wyłącznie na rzecz Klientów Testowych<br /><br />
              5. Usługa Konta świadczona jest wyłącznie na rzecz Użytkowników.<br /><br />
              6. Usługa Oddania Głosu świadczona jest na rzecz Uczestników.<br /><br />
              7. Użytkownicy oraz Uczestnicy mają obowiązek korzystania z Platformy w sposób zgodny z Regulaminem oraz przepisami prawa.<br /><br />
              8. W celu prawidłowego korzystania z Platformy i wszystkich jej funkcjonalności niezbędne jest spełnienie wymagań technicznych w postaci: posiadania urządzenia z dostępem do Internetu, posiadanie konta poczty elektronicznej, a także zainstalowanie na urządzeniu przeglądarki internetowej Microsoft Edge w wersji 40 lub wyższej, Google Chrome w wersji 58 lub wyższej, Mozilla Firefox w wersji 50 lub wyższej, Opera w wersji 44 lub wyższej albo Safari w wersji 9 lub wyższej. W używanej przeglądarce internetowej powinna być włączona obsługa plików cookies.
            </StyledParagraph>

            <StyledDigitHeader>03. Konto</StyledDigitHeader>
            <StyledParagraph>

              1. Usługa Konta świadczona jest nieodpłatnie.<br/><br />
              2. Umowa dotycząca usługi Konta zawarta jest z momentem dokonania rejestracji na Platformie i świadczona jest przez czas nieoznaczony. <br/><br />
              3. Konsument może odstąpić od umowy dotyczącej usługi Konta w terminie 14 dni od dnia jej zawarcia bez podawania przyczyny. PLEO prześle Konsumentowi niezwłocznie drogą mailową potwierdzenie otrzymania oświadczenia o odstąpieniu o umowy.<br /><br />
              4. Użytkownik ma możliwość zrezygnowania z usługi Konta w każdej chwili (wypowiedzenia umowy dotyczącej usługi Konta), bez podawania przyczyny. Rezygnacja z tej usługi może nastąpić przez przesłanie PLEO odpowiedniej informacji, w szczególności za pośrednictwem poczty elektronicznej lub pocztą tradycyjną. Po otrzymaniu oświadczenia PLEO niezwłocznie, nie później jednak niż w ciągu 14 dni, usunie Konto wraz z wszystkimi zebranymi danymi dotyczącymi korzystania z Konta. <br /><br />
              5. W przypadku usunięcia danych zebranych przez PLEO dotyczących korzystania z Konta ich odzyskanie w późniejszym czasie będzie niemożliwe. W przypadku rejestracji przez Użytkownika nowego Konta dane te również nie będą dostępne.<br /><br />
              6. Założenie Konta wymaga:<br/>
              <span className='innerText'>1)wypełnienia Formularza Rejestracji dostępnego na Platformie przez podanie wymaganych w nim danych, w tym imienia i nazwiska oraz adresu e-mail, które Użytkownik będzie używał do logowania się na Konto, a także wskazania nazwy Organizacji;</span>
              <br />
              <span className='innerText'>2)zaakceptowania postanowień niniejszego Regulaminu. Oświadczenie o akceptacji składa się drogą elektroniczną poprzez zaznaczenie odpowiedniej opcji pod Formularzem Rejestracji;</span><br />
              <span className='innerText'>3)kliknięcia przycisku „Zarejestruj”;</span><br />
              <span className='innerText'>4)ustawienia hasła poprzez kliknięcie przycisku „Utwórz hasło” umieszczonego w wiadomości e-mail wysłanej przez PLEO na adres podany w Formularzu Rejestracji i podania hasła do Konta.</span><br /><br />
              7. Użytkownik jest zobowiązany do podania w Formularzu Rejestracji danych prawidłowych i zgodnych z rzeczywistością. <br /><br />
              8. Wraz z wiadomością e-mail, o której mowa w § 3 ust. 6 pkt 4) Regulaminu, Użytkownik otrzymuje aktualny tekst Regulaminu.<br /><br />
              9. Po założeniu Konta Użytkownik uzyskuje do niego dostęp. Dostęp do Konta jest możliwy po&nbsp;zalogowaniu się na Platformie. <br /><br />
              10. Logowanie na Konto następuje przez wypełnienie odpowiedniego formularza dostępnego na Platformie i podanie wymaganych w nim danych tj. adresu e-mail i hasła Użytkownika oraz kliknięcie przycisku logowania. <br /><br />
              11. Użytkownik zobowiązany jest do korzystania wyłącznie z własnego Konta. Użytkownik zobowiązany jest do zachowania w tajemnicy oraz nieprzekazywania osobom trzecim danych umożliwiających dostęp do jego Konta.<br /><br />
              12. Użytkownik, który stworzył Organizację, ma możliwość dodawania do niej kolejnych Użytkowników. Każdy Użytkownik ma możliwość usuwania z Organizacji innych Użytkowników, z tym zastrzeżeniem, że każda Organizacja musi posiadać minimum jednego Użytkownika. Nie jest możliwe usunięcie z Organizacji Użytkownika, który ją stworzył.<br /><br />
              13. Użytkownik, który stworzył Organizację, jak również każdy Użytkownik dodany do tej Organizacji, jest uprawniony do zarządzania Organizacją, a w szczególności do edycji danych Organizacji.<br /><br />
              14. PLEO ma prawo do zakończenia świadczenia usługi Konta bez podawania przyczyny. PLEO poinformuje z 30 dniowym wyprzedzeniem o zakończeniu świadczenia usługi Konta.
            
            
            
            </StyledParagraph>



            <StyledDigitHeader>04. Wydarzenie</StyledDigitHeader>
            <StyledParagraph>

              1. Tworzenie Wydarzenia przez Użytkownika wymaga: <br /><br />
              <span className='innerText'>1) podania parametrów Wydarzenia, w tym w szczególności: określenia rodzaju i nazwy Wydarzenia, daty rozpoczęcia i zakończenia Wydarzenia, określenia rodzaju i nazwy Głosowania, sposobu uruchamiania Głosowania, określenia możliwości edytowania odpowiedzi przez Uczestników, sformułowania Pytań wraz z określeniem metody i czasu odpowiedzi oraz jawności Pytania; oraz</span><br />
              <span className='innerText'>2) dodania do Głosowania Uczestników poprzez wybór jednej z następujących metod:</span><br />
              <span className='innerText'>a) zaproszenie e-mail,</span><br />
               <span className='innerText'>b) zaproszenie SMS,</span><br />
               <span className='innerText'>c) zaproszenie w drodze wygenerowania unikalnych kodów,</span><br />
               <span className='innerText'>d) dostęp publiczny w drodze wygenerowania linku do Głosowania.</span><br /><br />
               2.Użytkownik może dodać do Głosowania Uczestników niemających możliwości OddaniaGłosu, a jedynie wglądu w Wydarzenie i przebieg Głosowania.<br /><br />
              3.Uczestnik,   oddając   głos,  może  być   Pełnomocnikiem   innej   osoby.   W   tym   celu Użytkownik,   który   tworzy  Wydarzenie,   może,   przy   określeniu   metody   dodawania Uczestników do Głosowania, wskazać dane takiego Pełnomocnika i osoby trzeciej, zaktórego będzie działał Pełnomocnik.<br /><br />


              4. W przypadku wyboru przez Użytkownika metody w postaci zaproszenia e-mail lub zaproszenia SMS, Użytkownik jest zobowiązany podać następujące dane Uczestnika: imię, nazwisko, adres e-mail (w   przypadku   zaproszenia   e-mail)  albo   numer telefonu (w   przypadku  zaproszenia   SMS). Zaproszenia e-mail i zaproszenia SMS wysyłane są Uczestnikowi bezpośrednio przez PLEO, ale na żądanie i w imieniu Użytkownika, który wprowadził dane Uczestnika do Platformy. Klient jest zobowiązany pozyskać zgodę Uczestnika na otrzymanie takiej informacji.<br /><br />
              5. Jeden adres e-mail i jeden numer telefonu mogą być przypisane wyłącznie do jednegoUczestnika. Przypisanie adresu e-mail oraz numeru telefonu do Uczestnika następujeprzez podanie ich przez Użytkownika przy zaproszeniu e-mail albo zaproszeniu SMS.<br /><br />
              6. Umowa dotycząca świadczenia na rzecz Klienta usługi Tworzenia Wydarzenia zawarta jest z momentem aktywacji subskrypcji, tj. po dokonaniu po sobie następujących czynności: (i) wyboru Planu Abonamentowego, (ii) dodania karty płatniczej, z której pobierane będą Opłaty. <br /><br />
              7. Umowa dotycząca świadczenia usługi Tworzenia Wydarzenia na rzecz Klienta zawarta jest na czas nieoznaczony. <br /><br />
              8. Warunkiem   utworzenia   Wydarzenia  przez   Użytkownika  jest   posiadanie   przezOrganizację, do której Użytkownik należy, Planu Abonamentowego umożliwiającegoutworzenie takiego Wydarzenia.<br /><br />

              9. Uczestnik dodany do Głosowania w drodze zaproszenia e-mail podlega autoryzacji.Autoryzacja przebiega w następujących krokach:<br /><br />
              <span className='innerText'> 1) otrzymanie przez  Uczestnika wiadomości e-mail informującego o zaproszeniu do Wydarzenia,</span><br/>
              <span className='innerText'> 2) przejście przez  Uczestnika na  adres internetowy podany  w e-mailu, o którymmowa w pkt 1 i podanie przez Uczestnika adresu e-mail, na który przesłana zostałainformacja o zaproszeniu do Wydarzenia,</span><br/>
              <span className='innerText'> 3) otrzymanie przez Uczestnika kodu weryfikacyjnego przesłanego na adres e-mailpodany przez Uczestnika,</span><br/>
              <span className='innerText'> 4) przejście przez Uczestnika na adres internetowy podany w e-mailu zawierającymkod weryfikacyjny i ustawienie indywidualnego hasła Uczestnika.</span><br/>

              10. Subskrypcja   staje   się   aktywna   z   momentem   dokonania   przez   Klienta  następujących czynności:(i) wyboru Planu Abonamentowego, (ii) dodania karty płatniczej, z której pobieranebędą Opłaty.<br /><br />
              11. Subskrypcja jest aktywna przez czas określony w jej  warunkach, tj. 31 dni lub 12miesięcy. Po zakończeniu Subskrypcji Klient nadal mawgląd w przebieg Wydarzenia (w tym również w wynik przeprowadzonych Głosowań),jednak nie może już korzystać z usługi Tworzenia Wydarzenia.<br /><br />
              12.W   ramach  Wydarzenia   Klient   może   korzystać   z   dodatkowych   funkcjonalnościPlatformy w postaci  generowania raportu, a także  czatu i wideokonferencji.  Raport,wygenerowany po zakończeniu Wydarzenia, zawiera informacje dotyczące przebiegu Wydarzenia, w tym szczegóły dotyczące poszczególnych Głosowań.  Czat umożliwia wymianę   wiadomości   tekstowych   między   dwoma   lub   wieloma   Użytkownikami.Wideokonferencja   umożliwia   równoczesną   łączność   między   dwoma   lub   wieloma Użytkownikami za pomocą dwukierunkowych transmisji audiowizualnych.<br /><br />
              13.Klient   Testowy  może   utworzyć   dwa   Wydarzenia   Testowe.  Użytkownik   staje   się Klientem   Testowym   z   momentem   zawarcia   przez   niego   umowy   dotyczącejświadczenia usługi Tworzenia Wydarzenia  Testowego, tj. po zadeklarowaniu przez Użytkownika chęci utworzenia Wydarzenia Testowego poprzez kliknięcie przez niego przycisku „Dodaj wydarzenie”, a następnie „Dodaj testowe”.<br /><br />
              14.Tworzenie Wydarzenia Testowego jest bezpłatne.<br /><br />
              15.W ramach Wydarzenia Testowego niedostępna jest funkcjonalność:<br /><br />
              <span className='innerText'> 1) dodawania Uczestników do Głosowania w drodze zaproszenia SMS</span><br/>
              <span className='innerText'> 2) organizowania wideokonferencji</span><br/>
              <span className='innerText'> 3) generowania raportów.</span><br/>
              16.Oddanie Głosu przez Uczestnika wymaga:<br /><br />
              <span className='innerText'> 1) akceptacji Regulaminu,</span><br/>
              <span className='innerText'> 2) autoryzacji Uczestnika przez PLEO (w przypadku zaproszenia e-mail)</span><br/>
              <span className='innerText'> 3) ustawienia  indywidualnego  hasła  poprzez kliknięcie przycisku „Utwórz hasło”  w podstronie  Platformy  (w   przypadku   zaproszenia  e-mail,   zaproszenia   SMS   oraz zaproszenia w drodze wygenerowania unikalnych kodów)</span><br/>
              <span className='innerText'> 4) zaznaczenia jednej lub wielu z dostępnych odpowiedzi w Pytaniu (Pytaniach) ipotwierdzenia wyboru poprzez kliknięcie przycisku „Zatwierdź głos”.</span><br/>
              17.Umowa dotycząca świadczenia na rzecz Uczestnika usługi Oddania Głosu zawarta jestz momentem akceptacji Regulaminu.<br /><br />
              18.Umowa dotycząca świadczenia usługi Oddania Głosu na rzecz Uczestnika zawarta jestna czas, w jakim Uczestnik może aktywnie wziąć udział w Głosowaniu, nie dłużej niżdo zakończenia trwania Wydarzenia lub Wydarzenia Testowego.<br /><br />
              19.PLEO dołoży wszelkich starań, aby transmisja danych w ramach korzystania zusługiTworzenia Wydarzenia oraz usługi Tworzenia Wydarzenia Testowego była bezpiecznatzn., aby przesyłane przez Internet informacje zostały transmitowane z zachowaniempoufności, całości i kompletności przesyłanych danych.<br /><br />
              20.Klient   ma   możliwość   rezygnacji   z  Subskrypcji  z  bez   podawania   przyczyny,   cospowoduje brak możliwości skorzystania z usługi Tworzenia Wydarzenia. Rezygnacja zusługi  Tworzenia  Wydarzenia  może  nastąpić  przez   usunięcie  karty  debetowej lubkredytowej, z której pobierana jest Opłata.<br /><br />
              21.PLEO ma prawo do zakończenia świadczenia usługi Tworzenia Wydarzenia na rzecz danego  Klienta bez podawania przyczyny. <br /><br />


            </StyledParagraph>



            <StyledDigitHeader>05. Usunięcie danych</StyledDigitHeader>
            <StyledParagraph>

              1. W momencie zakończenia świadczenia Usług, Osoby utracą dostęp do danych dotyczących korzystania z Usług. Dane te zostaną trwale usunięte przez PLEO w ciągu 30 dni od zakończenia świadczenia Usług, z zastrzeżeniem ust. 3. <br /><br />
              2. W przypadku usunięcia danych zebranych przez PLEO dotyczących korzystania z Usług ich odzyskanie w późniejszym czasie będzie niemożliwe. Dane te nie będą dostępne również w przypadku ponownego zawarcia przez Osobę umowy dotyczącej korzystania z uprzednio wybranej usługi. <br /><br />
              3. W przypadku złożenia stosownej dyspozycji drogą elektroniczną na adres e-mail <a href="mailto:biuro@wyborek.pl">biuro@wyborek.pl</a> w terminie 30 dni, o którym mowa w ust. 1, PLEO zobowiązuje się nie usuwać danych zebranych przez PLEO dotyczących korzystania z Usług przez okres kolejnych 30 dni licząc od dnia złożenia dyspozycji. Po upływie tego okresu dane zostaną trwale usunięte.

            </StyledParagraph>


            <StyledDigitHeader>06. Plan Abonamentowy</StyledDigitHeader>
            <StyledParagraph>

              1. Usługa Tworzenia Wydarzenia świadczona jest odpłatnie w ramach Subskrypcji,na warunkach wynikających z Planów Abonamentowych.<br /><br />
              2. Opłata w stałej części (określona w niezmiennej wysokości dla danego Planu Abonamentowego) pobierana jest z góry za cały okres Subskrypcji. Pozostała część Opłaty, wynikająca z przekroczenia liczby głosów dostępnych w ramach Planu Abonamentowego lub skorzystania z dodatkowych funkcjonalności Platformy (takich jak czat lub wideokonferencja) pobierana jest:<br /><br />
              <span className='innerText'> 1) w przypadku Subskrypcji miesięcznej – na koniec zakończeniu Subskrypcji,</span><br />
              <span className='innerText'> 2) w przypadku Subskrypcji rocznej – na koniec Miesiąca Rozliczeniowego.</span><br />
              3. Opłaty są pobierane poprzez obciążenie karty debetowej lub kredytowej, której dane zostały podane przez Klienta podczas wyboru Planu Abonamentowego.<br /><br />
              4. Subskrypcja  ulega  automatycznemu  przedłużeniu  na  okres, na   jaki  była  aktywna ostatnia  Subskrypcja Klienta  i  na warunkach  Planu Abonamentowego tożsamego  z tym, jaki obowiązywał Klienta ostatniego dnia tej Subskrypcji<br /><br />

              5. Klient ma możliwość zmiany Planu Abonamentowego w każdym czasie na Plan Abonamentowy, za który pobierana jest wyższa Opłata. W takim przypadku za pozostałą część okresu   Subskrypcji pobrana zostanie dodatkowa kwota, obliczona proporcjonalnie do wzrostu Opłaty za nowy Plan Abonamentowy.Dodatkowe  funkcjonalności  (takie   jak   czat,   wideokonferencja,generowanie raportów i inne) zrealizowane w bieżącym Miesiącu Rozliczeniowym(wprzypadku  Subskrypcji  rocznej)  albo  w  trakcie bieżącej  Subskrypcji  (w  przypadkuSubskrypcji miesięcznej) rozliczone będą według wyższego Planu Abonamentowego.<br /><br />
              6.Klient ma możliwość zmiany Planu Abonamentowego na Plan Abonamentowy, za którypobierana jest niższa Opłata w dowolnym momencie, ze skutkiem na pierwszy dzieńkolejnej  Subskrypcji.<br /><br />
              7. PLEO  jest   uprawnione  do   zmiany   Planów   Abonamentowych   w   każdym   czasie. Wszelkie zmiany Planów Abonamentowych będą obowiązywać Klienta odpierwszego dnia kolejnej Subskrypcji<br /><br />
              8. Wszelkie  informacje   związane   z   Subskrypcją,   w  tym   także   faktury   wynikające  ze świadczenia   usługi   Tworzenia  Wydarzenia,   udostępnione   będą   Klientowi  w  formie elektronicznej za pośrednictwem sieci Internet.<br /><br />
              9.Każdy Użytkownik zostanie każdorazowo poinformowany drogą mailową o zmianiePlanów Abonamentowych.
            </StyledParagraph>


            <StyledDigitHeader>07. Korzystanie z Usług</StyledDigitHeader>
            <StyledParagraph>

              1. Zabronione jest dostarczenie treści o charakterze bezprawnym w ramach korzystania z Usług.<br /><br />
              2. Klient oraz Klient Testowy są odpowiedzialni za treści dostarczane Użytkownikom i Uczestnikom oraz zbierane od Użytkowników i Uczestników w ramach korzystania z Usług. W szczególności Klient oraz  Klient Testowy są wyłącznie odpowiedzialni za komunikację prowadzoną z Użytkownikami i Uczestnikami w przypadkach, w których dochodzi do zbierania danych lub udzielania odpowiedzi w zakresie regulowanym przepisami prawa (np. w zakresie jakim komunikacja może zostać uznana za orzekanie o stanie zdrowia lub w zakresie, w jakim dochodzi do zbierania danych dotyczących zdrowia). <br /><br />
              3. Klient oraz Klient Testowy są zobowiązani zapewnić, że Użytkownicy i Uczestnicy korzystają z Usług w sposób zgodny z przepisami prawa powszechnie obowiązującego, dobrymi obyczajami lub uczciwymi praktykami rynkowymi. Klient oraz Klient Testowy zobowiązują się w szczególności, że Użytkownicy i Uczestnicy nie będą dostarczać pozostałym Użytkownikom i Uczestnikom treści sprzecznych z prawem powszechnie obowiązującym, dobrymi obyczajami lub uczciwymi praktykami rynkowymi, w szczególności treści rasistowskich, wulgarnych lub w inny sposób obraźliwych. <br /><br />
              4. PLEO zastrzega sobie możliwość zablokowania korzystania z Usług w przypadku naruszenia postanowień Regulaminu. Opłata z tytułu Subskrypcji, w czasie trwania której Usługa została zablokowana nie podlega zwrotowi w jakiejkolwiek części. Dalsze Opłaty nie będą pobierane.

            </StyledParagraph>




            <StyledDigitHeader>08. Reklamacje</StyledDigitHeader>
            <StyledParagraph>

              1. Użytkownik może złożyć reklamację dotyczącą świadczonych Usług w formie: <br /><br />
              <span className='innerText'> 1)pisemnej na adres podany w § 1 ust. 2 Regulaminu</span> <br />
              <span className='innerText'> 2)droga elektroniczną na adres e-mail: <a href="mailto:biuro@wyborek.pl">biuro@wyborek.pl</a>.</span><br />
              2. PLEO rozpatrzy reklamację i udzieli na nią odpowiedzi nie później niż w terminie 30&nbsp;dni od dnia jej otrzymania.

            </StyledParagraph>


            <StyledDigitHeader>09. Polubowne rozwiązywanie sporów</StyledDigitHeader>
            <StyledParagraph>

              1. PLEO oświadcza, że będzie w miarę możliwości dążyć do polubownego rozwiązywania wszelkich sporów powstałych pomiędzy PLEO a Konsumentem w wyniku korzystania z Platformy.<br /><br />
              2. Na stronie internetowej <a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/...</a> dostępna jest platforma ODR, utworzona na podstawie Rozporządzenia Parlamentu Europejskiego i Rady (UE) nr 524/2013 z dnia 2013 r. w sprawie internetowego systemu rozstrzygania sporów konsumenckich oraz zmiany rozporządzenia (WE) nr 2006/2004 i dyrektywy 2009/22/WE (rozporządzenie w sprawie ODR w sporach konsumenckich). Platforma ODR stanowi punkt dostępu dla konsumentów i przedsiębiorców umożliwiający im pozasądowe rozstrzyganie sporów objętych ww. rozporządzeniem.<br /><br />
             3. W przypadku sporu pomiędzy PLEO a Konsumentem w przedmiocie złożonej reklamacji, Konsument może skorzystać z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń. Konsument może w szczególności złożyć wniosek o wszczęcie mediacji lub rozpatrzenie sprawy przez sąd polubowny, lub też skorzystać z pomocy powiatowego (miejskiego) rzecznika praw konsumenta.<br /><br />
              4. Szczegółowe informacje dotyczące możliwości skorzystania przez Konsumenta z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń dostępne są m.in. na stronie internetowej Urzędu Ochrony Konkurencji i Konsumentów www.uokik.gov.pl, a także na stronach internetowych Wojewódzkich Inspektoratów Inspekcji Handlowych.

            </StyledParagraph>



            <StyledDigitHeader>10. Odpowiedzialność PLEO</StyledDigitHeader>
            <StyledParagraph>

              1. Odpowiedzialność PLEO z tytułu szkód wyrządzonych Klientowi w związku z niewykonaniem lub nienależytym wykonaniem usługi Tworzenia Wydarzenia ograniczona jest do szkód rzeczywiście poniesionych, a łączne odszkodowanie z tytułu szkód poniesionych przez Klienta nie może przekroczyć kwoty 100 zł.<br /><br />
              2. Strony zgodnie wyłączają odpowiedzialność PLEO z tytułu utraconych korzyści.<br /><br />
              3. PLEO nie ponosi odpowiedzialności za niewykonanie lub nienależyte wykonanie usługi Tworzenia Wydarzenia w przypadkach, których niewykonanie lub nienależyte wykonanie tej usługi było wynikiem okoliczności dotyczących podmiotów trzecich.<br /><br />
              4. PLEO nie ponosi odpowiedzialności z tytułu naruszenia jakichkolwiek praw lub dóbr osobistych osób trzecich oraz z tytułu wyrządzenia szkody osobom trzecim w związku z korzystaniem przez Osoby z Usług ani w związku z wykorzystaniem przez Osoby danych zebranych podczas korzystania z Usług.


            </StyledParagraph>



            <StyledDigitHeader>11. Przerwa techniczna</StyledDigitHeader>
            <StyledParagraph>

              1. PLEO jest uprawnione do zarządzenia z przyczyn technicznych czasowej przerwy w działaniu Platformy, Konta lub Usług.<br /><br />
              2. PLEO dołoży starań aby:<br /><br />
              <span className='innerText'> 1)powiadomić Osoby o planowanych przerwach technicznych;</span><br />
                <span className='innerText'> 2)przerwy techniczne trwały jak najkrócej.</span>


            </StyledParagraph>




            <StyledDigitHeader>12. Dane osobowe</StyledDigitHeader>
            <StyledParagraph>

              1. Administratorem danych osobowych Użytkowników jest PLEO interactive spółka z ograniczoną odpowiedzialnością z siedzibą w Krakowie, ul. Bolesława Limanowskiego 36/30, 30-551 Kraków, wpisaną do Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy dla Krakowa – Śródmieścia w Krakowie, Wydział XI Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000429590, NIP: 6793084198, REGON: 122635706, kapitał zakładowy: 5.000,00 PLN.<br /><br />
              2. Przetwarzanie danych osobowych Klientów i Użytkowników odbywa się̨ zgodnie z przepisami powszechnie obowiązującego prawa, a w szczególności zgodnie z&nbsp;Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. („RODO”), a także zgodnie z niniejszym Regulaminem.<br /><br />
              3. W ramach działalności Platformy przetwarzane są dane osobowe podane przez Klientów i Użytkowników takie jak imię i nazwisko, nazwa firmy, NIP, REGON, adres siedziby, adres korespondencyjny, jeżeli jest inny niż adres siedziby, adres e-mail, numer karty kredytowej lub debetowej, numer konta bankowego. <br /><br />
              4. Podanie danych osobowych jest dobrowolne, ale niezbędne w celu zawarcia i realizacji umowy.<br /><br />
              5. Przetwarzanie danych osobowych Klientów i Użytkowników następuje w celu zawarcia i wykonania umów o świadczenie usług drogą elektroniczną określonych w Regulaminie (art. 6 ust. 1 lit. b RODO). PLEO przechowuje je&nbsp;maksymalnie przez okres 5 lat od dnia zaprzestania korzystania przez Klienta lub Użytkownika z&nbsp;danej usługi.<br /><br />
              6. Osobie, której dane dotyczą przysługują następujące prawa:<br /><br />
              <span className='innerText'> 1)prawo dostępu do podanych danych osobowych oraz prawo do otrzymania ich kopii;</span><br />
              <span className='innerText'> 2)prawo do sprostowania danych osobowych;</span><br />
              <span className='innerText'> 3)prawo do usunięcia danych osobowych;</span><br />
              <span className='innerText'> 4)prawo do żądania ograniczenia przetwarzania danych osobowych;</span><br />
              <span className='innerText'> 5)prawo do przeniesienia danych osobowych; </span><br />
              <span className='innerText'> 6)prawo sprzeciwu wobec przetwarzania podanych danych osobowych;</span><br />
              <span className='innerText'> 7)prawo do wniesienia skargi do organu nadzorczego.</span><br />
              7. PLEO zapewnia ochronę̨ danych osobowych, które przetwarza. W szczególności PLEO stosuje odpowiednie zabezpieczenia techniczne i środki organizacyjne zapewniające ochronę przetwarzanych danych osobowych, odpowiednie do zagrożeń oraz kategorii danych objętych ochroną. W szczególności zabezpiecza dane przed ich udostepnieniem osobom nieupoważnionym, zabraniem przez osobę̨ nieuprawnioną, przetwarzaniem z naruszeniem przepisów oraz zmianą, utratą, uszkodzeniem lub&nbsp;zniszczeniem.<br /><br />
              8. W celu skorzystania z jakiegokolwiek prawa, o którym mowa wyżej, osoba której dane dotyczą może skierować do PLEO żądanie za pośrednictwem poczty elektronicznej lub pocztą tradycyjną.<br /><br />
              9. W ramach działalności Platformy zbierane mogą być ponadto informacje o czasie nadejścia zapytania i wysłania odpowiedzi, adresie strony internetowej, z której nastąpiło przekierowanie do Platformy oraz rodzaju oprogramowania, z&nbsp;którego korzysta użytkownik końcowy. Informacje te są wykorzystywane dla celów administrowania Platformą.<br /><br />
              10. W   związku   z   korzystaniem   z   usługi   Tworzenia   Wydarzenia   może   dojść   do przetwarzania danych osobowych Uczestników lub osób trzecich (jeżeli dane takich osób   trzecich   zostały   wprowadzone   do   Platformy   przez   Uczestników   lub Użytkowników), przez PLEO w imieniu Klienta oraz Klienta Testowego, stąd też Klient oraz  Klient   Testowy  zawiera   z   PLEO   umowę   powierzenia   przetwarzania   danych osobowych. Umowa powierzenia przetwarzania danych osobowych stanowi załącznik nr   1   do   Regulaminu   i   wiąże   Klienta   od   chwili   aktywacji  Ssubskrypcji,   a   Klienta Testowego – od chwili zawarcia przez niego umowy dotyczącej świadczenia usługiTworzenia Wydarzenia Testowego.


            </StyledParagraph>





            <StyledDigitHeader>13. Pliki cookies</StyledDigitHeader>
            <StyledParagraph>

              1. W ramach działalności Platformy są używane niewielkie pliki zwane cookies. Pliki te&nbsp;są&nbsp;zapisywane przez serwer na komputerze użytkownika końcowego. Przez używanie plików cookies należy rozumieć ich przechowywanie i uzyskiwanie do nich dostępu przez&nbsp;PLEO lub podmioty z nim współpracujące. <br /><br />
              2. Pliki cookies mogą być: <br /><br />
              <span className='innerText'> 1) plikami sesyjnymi, co oznacza, że są używane do momentu opuszczenia strony Platformy przez użytkownika końcowego, lub do momentu zamknięcia przez niego przeglądarki internetowej; </span><br />
              <span className='innerText'> 2) plikami stałymi, co oznacza, że pozostają na urządzeniu użytkownika końcowego przez czas określony w ich parametrach lub do momentu ich usunięcia przez&nbsp;użytkownika końcowego. </span><br />
              3. Pliki cookies mogą być używane w następujących celach: <br /><br />
              <span className='innerText'> 1) umożliwienia korzystania z usług dostępnych na stronie Platformy; </span><br />
              <span className='innerText'> 2) zbierania informacji o sposobie korzystania przez użytkowników końcowych z Platformy;</span><br />
              <span className='innerText'> 3) zapamiętania wybranych przez użytkownika końcowego ustawień strony internetowej; </span><br />
              <span className='innerText'> 4) zapamiętania adresu e-mail i hasła za pomocą, których użytkownik końcowy loguje się do Platformy. </span><br />
              4. Używanie plików cookies nie powoduje zmian konfiguracyjnych w urządzeniu użytkownika końcowego i oprogramowaniu zainstalowanym na tym urządzeniu. <br /><br />
              5. W ramach działalności Platformy mogą być stosowane również pliki cookies podmiotów zewnętrznych pochodzące np. z serwerów reklamowych, serwerów firm i dostawców usług współpracujących z PLEO. <br /><br />
              6. Domyślne ustawienia przeglądarek internetowych dopuszczają zwykle zapisywanie plików cookies w urządzeniach końcowych. Ustawienia te mogą jednak zostać zmienione przez użytkownika końcowego. <br /><br />
              7. Użytkownik końcowy może ograniczyć lub wyłączyć dostęp plików cookies do swojego komputera za pomocą ustawień przeglądarki internetowej. W przypadku skorzystania z tej opcji mogą jednak wystąpić problemy z działaniem niektórych funkcjonalności Platformy. <br /><br />
              8. Zgodnie z przepisami ustawy prawo telekomunikacyjne abonent lub użytkownik końcowy może wyrazić zgodę na przechowywanie informacji lub uzyskiwanie dostępu do informacji już przechowywanej w telekomunikacyjnym urządzeniu końcowym abonenta lub użytkownika końcowego również za pomocą ustawień oprogramowania zainstalowanego w wykorzystywanym przez niego telekomunikacyjnym urządzeniu końcowym lub konfiguracji usługi. W przypadku zatem gdyby użytkownik końcowy nie chciał wyrazić takiej zgody, powinien dokonać zmiany ustawień przeglądarki internetowej. <br /><br />
              9. Szczegółowe informacje w zakresie zmiany ustawień przeglądarki dotyczących plików cookies oraz ich usunięcia uzyskać można na oficjalnej stronie internetowej konkretnej przeglądarki. W szczególności powyższe informacje można znaleźć pod następującymi adresami stron internetowych: <br /><br />
              przeglądarka Firefox; <br /><br />
              przeglądarka Chrome; <br /><br />
              przeglądarka Microsoft Edge; <br /><br />
              przeglądarka Opera; <br /><br />
              przeglądarka Safari.


            </StyledParagraph>




            <StyledDigitHeader>14. Zmiana Regulaminu</StyledDigitHeader>
            <StyledParagraph>

              1. PLEO jest uprawnione do zmiany niniejszego Regulaminu z powodów takich jak&nbsp;zmiana przepisów prawa, zmiana danych podawanych w formularzach, zmiany technologiczne, zmiana funkcjonalności Platformy. <br /><br />
              2. Zmiana Regulaminu wchodzi w życie w terminie wskazanym przez PLEO – który każdorazowo nie będzie krótszy niż 7 dni od dnia udostępnienia nowego Regulaminu w&nbsp;Platformie. <br /><br />
              3. Każda   Osoba   zostanie   poinformowana   o   zmianach   w   Regulaminie.   Zmiany Regulaminu   wiążą   Osobę,   jeżeli   nie   wypowiedziała   ona   zawartych   umów   oświadczenie   usług   (umowy   dotyczącej   usługi   Tworzenia   Wydarzenia,  umowy dotyczącej usługi Tworzenia Wydarzenia Testowego, umowy dotyczącej usługi Konta,umowy dotyczącej usługi Oddania Głosu) oraz nie złożyła dyspozycji zaprzestania świadczenia tychże usług terminie 7 dni od dnia powiadomienia jej o zmianach. <br /><br />
              4. W przypadku rezygnacji z Subskrypcji oraz złożenia dyspozycji zaprzestania świadczenia usługi Tworzenia Wydarzenia przed zakończeniem  Subskrypcji w terminie, o którym mowa w ust. 3, Opłata zUsług  usługi Tworzenia Wydarzenia  przed  zakończeniem  zakończeniem  SubskrypcjiOkresu Rozliczeniowego  w terminie, o którym mowa w ust. 3, Opłata  dokonana w związku z Subskrypcją podlega proporcjonalnemu zwrotowi.  Jeżeli Klient wybrał subskrypcję roczną, wówczas zwrotowi podlegać Opłata pomniejszona o Opłatę za poprzednie Miesiące Rozliczeniowe oraz proporcjonalnie za Miesiąc Rozliczeniowy, w którym zrezygnowano   z Subskrypcji.


            </StyledParagraph>


            <StyledDigitHeader>15. Postanowienia końcowe</StyledDigitHeader>
            <StyledParagraph>

              1. Niniejszy Regulamin dostępny jest na stronie internetowej Platformy oraz w siedzibie PLEO.<br /><br />
              2. Prawem właściwym dla zobowiązań wynikających z Regulaminu jest prawo polskie. Sądem właściwym do rozpatrywania spraw związanych ze świadczeniem Usług jest sąd właściwy miejscowo dla siedziby PLEO. <br /><br />
              3. W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie przepisy prawa polskiego, w szczególności Kodeks cywilny oraz ustawy o świadczeniu usług drogą elektroniczną. <br /><br />
              4. Regulamin obowiązuje od dnia 01.01.2021r.


            </StyledParagraph>


            <StyledDigitHeader>UMOWA POWIERZENIA PRZETWARZANIA DANYCH OSOBOWYCH</StyledDigitHeader>
            <StyledParagraph>Klientem lub Klientem Testowym (dalej: „Administrator”), a PLEO (dalej: „Przetwarzający”) zwanymi dalej łącznie „Stronami”.</StyledParagraph><br /><br />

            <StyledParagraph>
            <span className='innerText'> 1) Umowa (dalej: „Umowa”) stanowi integralną część Regulaminu</span><br />
            <span className='innerText'> 2) Umowa określa zasady przetwarzania danych osobowych Uczestników i osób trzecich,jeżeli   dane   takich   osób   trzecich   zostały   wprowadzone   przez   Uczestników   lubUżytkowników (dalej: „Osoby Trzecie”);</span><br />
            <span className='innerText'> 3) PLEO jest podmiotem przetwarzającym w imieniu Klienta oraz Klienta Testowego dane osobowe   Uczestników   i   Osób   Trzecich   zbierane   w   ramach   świadczenia   usługiTworzenia Wydarzenia;</span><br />
            <span className='innerText'> 4) Umowa reguluje zasady  przetwarzania  przez PLEO danych  osobowych  w imieniu Klienta oraz Klienta Testowego w taki sposób, aby odpowiadały one postanowieniomrozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych   i   w   sprawie   swobodnego   przepływu   takich   danych   oraz   uchyleniadyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz. U. UE. L. z 2016r. Nr 119, str. 1) – dalej „RODO”;</span><br />
            <span className='innerText'> 5) Terminy użyte w Umowie, które są pisane z wielkich liter, a nie zostały zdefiniowane wUmowie, mają znaczenie określone w Regulaminie</span><br />
            </StyledParagraph>

            <StyledDigitHeader>1. Postanowienia ogólne</StyledDigitHeader>
            <StyledParagraph>

              1. Administrator powierza Przetwarzającemu przetwarzanie opisanych w Umowie danychosobowych, na zasadach i w celu określonym w Umowie oraz Regulaminie.<br /><br />
              2. Przetwarzanie danych  osobowych zostaje powierzone Przetwarzającemu w okresiekorzystania przez Administratora z usługi Tworzenia Wydarzenia albo usługi TworzeniaWydarzenia Testowego. W przypadku zaprzestania korzystania z usługi TworzeniaWydarzenia oraz Tworzenia Wydarzenia Testowego Umowa wygasa, z zastrzeżeniem okresu, w którym zgodnie z § 5 ust. 3 Regulaminu, Przetwarzający zobowiązuje się nieusuwać danych zebranych przez Przetwarzającego, dotyczących korzystania z Usług. <br /><br />
              3. Przetwarzający przetwarza dane osobowe wyłącznie zgodnie z udokumentowanymipolecaniami Administratora. Umowa oraz Regulamin stanowią takie udokumentowanepolecenie przetwarzania <br /><br />


            </StyledParagraph>


            <StyledDigitHeader>2. Charakter i cel przetwarzania</StyledDigitHeader>
            <StyledParagraph>

              1. Charakter i cel przetwarzania wynikają z Regulaminu, w tym w szczególności:<br /><br />
              <span className='innerText'> 1) charakter   przetwarzania   wynika   z   zobowiązań   Przetwarzającego   zawartych   w Regulaminie tj. świadczenia usługi Tworzenia Wydarzenia  oraz usługi TworzeniaWydarzenia Testowego</span><br />
              <span className='innerText'> 2) celem   przetwarzania   jest   umożliwienie   Administratorowi   korzystania   z   usługiTworzenia Wydarzenialub usługi Tworzenia Wydarzenia Testowego. </span><br />
              2. Przetwarzający nie jest uprawniony do przetwarzania danych osobowych do żadnychinnych celów.<br /><br />


            </StyledParagraph>



            <StyledDigitHeader>3. Rodzaj danych i kategorie osób</StyledDigitHeader>
            <StyledParagraph>

              1. Powierzenie przetwarzania dotyczy wszystkich danych osobowych Uczestników lubOsób   Trzecich   zebranych   od   Użytkowników   lub   wprowadzonych   przez   samychUczestników   w   ramach   korzystania   z   usługi   Tworzenia   Wydarzenia  lub   usługiTworzenia Wydarzenia Testowego i usługi Oddania Głosu, w tym m.in. imię i nazwisko,adres e-mail<br /><br />
              2. Dane osobowe, o których mowa w ust. 1 dotyczą Uczestników lub Osób Trzecich.<br /><br />

            </StyledParagraph>



            <StyledDigitHeader>4. Prawa i obowiązki Przetwarzającego</StyledDigitHeader>
            <StyledParagraph>

              1. Przetwarzający zobowiązuje się do wykonywania Umowy z zachowaniem najwyższejstaranności.<br /><br />
              2. Przetwarzający:<br /><br />
              <span className='innerText'> 1) oświadcza, że nie przekazuje danych do państwa trzecieg poza EOG luborganizacji międzynarodowej, za wyjątkiem korzystania z dostawców usługinformatycznych mających siedzibę poza EOG lub mających siedzibę w EOG, leczprzetwarzających dane poza EOG, z których to usług Przetwarzający korzysta przyokazji świadczenia usługi Tworzenia Wydarzenia  i usługi Tworzenia WydarzeniaTestowego; Przetwarzający korzysta wyłącznie z podmiotów mających siedzibę wkrajach,   co   do   których   Komisja   Europejska   stwierdziła,   że   zapewniają   oneodpowiedni poziom ochrony lub z podmiotów przetwarzających dane osobowe napodstawie   Standardowych   Klauzul   Umownych   przyjętych   przez   KomisjęEuropejską, o których mowa w art. 46 RODO, zawartych między Przetwarzającyma tym podmiotem; </span><br />
              <span className='innerText'> 2) potwierdza, że poza przypadkami wskazanymi w pkt 1) przekazanie powierzonychdanych do państwa trzeciego może nastąpić, gdy obowiązek taki nakładają naPrzetwarzającego   obowiązujące   przepisy   prawa;   w   takim   przypadku   przedrozpoczęciem   przetwarzania   Przetwarzający   informuje   Administratora   o   tymobowiązku prawnym, o ile prawo to nie zabrania udzielania takiej informacji zuwagi na ważny interes publiczny.</span><br />
              <span className='innerText'> 3) zapewnia, że osoby upoważnione do przetwarzania danych osobowych w związkuz realizacją Umowy zostaną zobowiązane do zachowania tajemnicy;</span><br />
              <span className='innerText'> 4) zapewnia podjęcie środków dotyczących bezpieczeństwa przetwarzania danychosobowych wymaganych na mocy art.32 RODO, w szczególności środkówopisanych w Umowie;</span><br />
              <span className='innerText'> 5) przestrzega warunków korzystania z usług Subprocesora określonych w Umowie</span><br />
              3. Jeżeli   Przetwarzający   poweźmie   wątpliwość   co   do   zgodności   z   prawem   poleceniawydanego   mu   przez   Administratora,   Przetwarzający   natychmiast   poinformujeAdministratora o powziętych wątpliwościach.<br /><br />
              4. Przetwarzający   zobowiązuje   się   do   poinformowania   Administratora   o   tym,   czyprowadzi   rejestr   wszystkich   kategorii   czynności   przetwarzania   dokonywanych   wimieniu Administratora, o którym mowa w art. 30 ust. 2 RODO. <br /><br />

            </StyledParagraph>



            <StyledDigitHeader>5. Prawa i obowiązki Administratora</StyledDigitHeader>
            <StyledParagraph>

              1. Administrator oświadcza, że jest administratorem danych osobowych powierzanych napodstawie Umowy oraz że jest uprawniony do ich przetwarzania w zakresie orazcelach, w jakim powierza przetwarzanie Przetwarzającemu.<br /><br />
              2. Administrator zobowiązuje się współdziałać z Przetwarzającym w zakresie, w jakimjest to niezbędne dla wykonania Umowy, usługi Tworzenia Wydarzenia  orazprzestrzegania przepisów RODO.<br /><br />
              3. W przypadku, gdyby Przetwarzający zgłosił Administratorowi wątpliwości co dozgodnośc z prawem polecenia wydanego  mu przez Administratora,  Administratorprzekaże Przetwarzającemu stosowne wyjaśnienia<br /><br />

            </StyledParagraph>



            <StyledDigitHeader>6. Współpraca Administratora i Przetwarzającego</StyledDigitHeader>
            <StyledParagraph>

              1. Administrator i Przetwarzający współpracują w zakresie, w jakim jest to niezbędne dlaprzestrzegania przepisów RODO.<br /><br />
              2. Przetwarzający  pomaga  Administratorowi poprzez odpowiednie środki techniczne iorganizacyjne wywiązać się z obowiązku odpowiadania na żądania osoby, której danedotyczą, w zakresie wykonywania jej praw określonych w rozdziale III RODO. <br /><br />
              3. Przetwarzający pomaga Administratorowi wywiązać się z obowiązków określonych wart. 32–36 RODO dotyczących bezpieczeństwa danych osobowych.<br /><br />
              4. Przetwarzający   udostępnia   Administratorowi   wszelkie   informacje   niezbędne   dowykazania spełnienia obowiązków określonych w Umowie i art. 28 RODO.<br /><br />

            </StyledParagraph>



            <StyledDigitHeader>7. Podpowierzenie</StyledDigitHeader>
            <StyledParagraph>

              1. Administrator wyraża ogólną zgodę na dalsze powierzenie przez Przetwarzającegoprzetwarzania danych osobowych objętych Umową w zakresie konkretnych operacjidalszym Przetwarzającym („Subprocesorom”).<br /><br />
              2. Lista aktualnych Subprocesorów, z których korzysta Przetwarzający jest dostępna naPlatformie.<br /><br />
              3. Podpowierzenie nie może obejmować całości powierzenia wynikającego z Umowy iRegulaminu.<br /><br />
              4. Przetwarzający   informuje   Administratora   o   wszelkich   zamierzonych   zmianachdotyczących dodania lub zastąpienia innych podmiotów przetwarzających, dając tymsamym Administratorowi możliwość wyrażenia sprzeciwu wobec takich zmian.<br /><br />

            </StyledParagraph>


            <StyledDigitHeader>8. Bezpieczeństwo danych</StyledDigitHeader>
            <StyledParagraph>

              1. Przetwarzający oświadcza, że przetwarzanie powierzonych danych odbywa się zzachowaniem odpowiednich środków technicznych i organizacyjnych, w szczególnościwskazanych w art. 32 RODO.<br /><br />
              2. Przetwarzający uwzględniając charakter, zakres, kontekst, cele przetwarzania orazryzyko naruszenia praw lub wolności osób, których dane dotyczą wdrożył niezbędneśrodki, aby zapewnić bezpieczeństwo przetwarzanych danych osobowych.<br /><br />
              3. Przetwarzający zapewnia, że stopień bezpieczeństwa jest odpowiedni i uwzględnia wszczególności   ryzyko   wiążące   się   zprzetwarzaniem,   wtym   wynikającezprzypadkowego   lub   niezgodnego   zprawem   zniszczenia,   utraty,   modyfikacji,nieuprawnionego   ujawnienia   lub   nieuprawnionego   dostępu   do   danych   osobowychprzesyłanych, przechowywanych lub winny sposób przetwarzanych.<br /><br />

            </StyledParagraph>


            <StyledDigitHeader>9. Procedura na wypadek naruszeń</StyledDigitHeader>
            <StyledParagraph>

              1. W przypadku, gdyby doszło do naruszenia ochrony danych osobowych,Przetwarzający bez zbędnej zwłoki zgłasza Administratorowi, że doszło do naruszenia.<br /><br />
              2. Przetwarzając wraz ze zgłoszeniem przekazuje Administratorowi wyjaśnienia dotyczące naruszenia i wszelką niezbędną dokumentację dotyczącą naruszenia w celu umożliwienia Administratorowi spełnienia obowiązku zgłoszenia naruszenia organowi nadzoru.<br /><br />
              3. Przetwarzający umożliwia Administratorowi wzięcie udziału w czynnościachwyjaśniających okoliczności i zakres naruszenia<br /><br />

            </StyledParagraph>


            <StyledDigitHeader>10. Odpowiedzialność Stron</StyledDigitHeader>
            <StyledParagraph>

              Przetwarzający odpowiada za szkody spowodowane przetwarzaniem wyłącznie, gdy niedopełnił obowiązków, które RODO nakłada bezpośrednio na Przetwarzającego lub gdydziałał poza zgodnymi zprawem instrukcjami Administratora lub wbrew tym instrukcjom.

            </StyledParagraph>


            <StyledDigitHeader>11. Usunięcie danych</StyledDigitHeader>
            <StyledParagraph>

              Po zakończeniu świadczenia usługi Tworzenia Wydarzenia i usługi Tworzenia Wydarzenia Testowego Przetwarzający usuwa dane osobowe zgodnie z postanowieniami Regulaminuoraz usuwa wszelkie ich istniejące kopie, chyba że przepisy prawa powszechnieobowiązującego nakazują mu dalsze przechowywanie danych osobowych

            </StyledParagraph>



          </StyledModuleWrapper>
        </StyledContainer>
        </StyledBackgroundWrapper>
      </StyledHidden>
    </Layout>
  )
}

export default regulamin
